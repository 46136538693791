.container {

	.row + .row {
		margin-top: 8px;
	}

	.cell + .cell {
		margin-left: 16px;
	}
}

.container_draggable {
	.row {
		margin-bottom: 8px;
	}

	.cell {
		margin-left: 16px;
	}
}

.element_list {
	transition: background-color 0.2s ease;
	background-color: 'unset';
	height: 100%;
	min-height: 100%;

	.dragging_over {
		background-color: var(--dragndrop-over-active-color);
	}
}

.element_wrapper {
	background-color: 'unset';

	&.dragging {
		background-color: var(--dragndrop-active-color);
	}
}
