.theme {
	// brand
	--primary-color: var(--brand-color);
	--secondary-color: #0F996D;
	--neutral-color: #646464;
	--destructive-color: rgba(244, 67, 54, 0.8);

	--error-color: #FF245A;

	// dom
	--h1-color: #E0E0E0;
	--h2-color: #E0E0E0;
	--h3-color: #D6D6D6;
	--h4-color: #D6D6D6;
	--h5-color: #D6D6D6;
	--h6-color: #D6D6D6;
	--scroll-bar-background: #616161;

	// shell

	--app-header-background: #262626;
	--app-header-color: #E0E0E0;
	--app-header-active-color: var(--brand-color);
	--app-header-bottom-separator-background: none;

	--user-panel-background: #393939;
	--user-panel-color: #B3B3B3;
	--user-panel-item-active-background: #515151;
	--user-panel-item-active-color: #fff;
	--user-panel-item-separator-background: #262B29;

	--menu-background: #1D1D1D;
	--menu-color: #d8d8d8;
	--submenu-background: #222222;
	--submenu-color: #B5B5B5;
	--submenu-selected-color: var(--brand-color);
	--submenu-hover-color: #fff;

	--content-background: #313131;
	--content-color: #D8D8D8;
	--content-title-color:  var(--brand-color);
	--content-subtitle-color:  var(--brand-color);
	--content-separator-background: #6C6C6C6E;
	--content-selected-color:  var(--brand-color);

	--help-background: #222222;
	--help-color: #B3B3B3;
	--help-box-shadow: 0px 2px 3px #000000B0;
	--help-title-color:  var(--brand-color);
	--help-subtitle-color: #D6D6D6;
	--help-toggler-background: var(--brand-color);
	--help-toggler-color: #fff;

	--with-fetch-background: #26262680; // var(--content-background)80 nakon sto se izbaci transparency


	// components

	--field-background: #1A1A1A;
	--field-color: #FFFFFF;
	--field-border-color: transparent;
	--field-disabled-background: #1A1A1A33;
	--field-disabled-color: #A0A0A0;
	--field-placeholder-color: #6A6A6A;
	--field-focus-border-color: var(--content-selected-color);
	--field-max-length-background: var(--brand-color);
	--field-max-length-color: #ffffff;
	--field-error: var(--error-color);
	--field-warning: #F5C050;
	--checkbox-checked-background: var(--brand-color);
	--checkbox-unchecked-background: var(--field-background);
	--radio-checked-background: var(--brand-color);
	--radio-unchecked-background: transparent;
	--switch-track-border-color: var(--field-border-color);
	--switch-track-checked-background: var(--field-background);
	--switch-track-unchecked-background: var(--field-background);
	--switch-thumb-checked-background: var(--brand-color);
	--switch-thumb-unchecked-background: var(--content-color);
	--textarea-icon-formating-color: #6A6A6A;
	// dropdown
	--field-dropdown-background: #393939;
	--field-dropdown-active-background: #464646;
	--field-dropdown-active-color: unset;
	--field-dropdown-no-options-color: #909090;

	--header-separator-color: #6c6c6c66;

	--table-header-color: #909090;
	--table-header-active-color: #fff;
	--table-header-separator-color: var(--header-separator-color);
	--table-row-active-background: #464646;
	--table-row-hover-background: #3e3e3e;
	--table-row-active-color: unset;
	--table-separator-color: #6c6c6c26;
	--table-active-color: var(--content-selected-color);
	--table-error: var(--error-color);
	--table-frozen-separator-color: var(--table-separator-color);
	--table-tooltip-background: #292a2d;
	--paging-disabled-color: #6A6A6A;
	--table-active-filter-background: #4747474D;
	--skeleton-cell-background: #43434380;
	--resize-indicator-active-color: var(--table-header-color);
	--resize-indicator-hover-color: #4e4e4e;

	--tabulator-background: var(--content-background);

	--dragndrop-border-color: var(--content-separator-background);
	--dragndrop-active-color: var(--brand-color);
	--dragndrop-over-active-color: var(--brand-color);
	--dragndrop-hover-background: #3F4041;

	--popup-background: #393939;
	--popup-color: #B3B3B3;
	--popup-border-color: #262B29;

	--color-picker-simple-background: #393939;
    --color-picker-simple-border-color: #262B29;

	--tab-color: #c0c0c0;
	--tab-active-color: #fff;
	--tab-active-background: var(--brand-color);
	--tab-separator-color: var(--header-separator-color);

	--widget-background: #2d2d2d;
	--widget-separator: #3c3c3c;
	--legend-inactive: #999999;

	--note-backgrounnd: #3c3c3c;

	--map-item-background: #3f4041;
	--map-item-add-background: transparent;
	--map-item-add-border-color: #6c6c6c66;
	--map-item-selected-color: none;
	--map-item-selected-background: var(--content-selected-color);
	--map-line-background: var(--map-item-add-border-color);


	// button group

	--primary-hover-color: var(--content-color);
	--primary-unselected-background: #4a4a4a;
	--primary-selected-background: var(--primary-color);
	--primary-border-color: #313131;
	--primary-hover-background: #3274c4;

	--neutral-hover-color: var(--content-color);
	--neutral-unselected-background: #4a4a4a;
	--neutral-selected-background: var(--neutral-color);
	--neutral-border-color: #313131;
	--neutral-hover-background: #545454;

}
