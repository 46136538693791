.container {
	display: flex;
	flex-direction: column;

	>:first-child {
		.row {
			border-top: 1px solid var(--table-header-separator-color);
		}
	}
}

.row_wrapper {
	display: flex;
	font-weight: 700;
}

.row {
	display: flex;
	flex: 1;
    box-sizing: border-box;
	background: var(--tabulator-background);
	border-bottom: 1px solid var(--table-separator-color);

	// added because we need to fill space between other cells and frozen right
	.cells_wrapper {
		display: flex;
		flex: 1;
	}
}

.cell {
	height: 32px; // when cell is empty, take height as row
	padding: 8px;
	cursor: pointer;
	position: relative;

	// for frozen cells not to be transparent when horizontal scrolling (row has background, not cell)
	&:is(.frozen) {
		background: var(--tabulator-background);
	}

	&.editing {
		overflow: hidden;
		padding: 4px;
	}

	.content {
		display: block;
		// shorten
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&.align_center {
			text-align: center;
		}

		&.align_right {
			text-align: right;
		}
	}
}

.frozen {
	position: sticky;
	z-index: 1;
}
